import { useCallback } from "react";

import { useCurrentUi } from "@keepeek/commons";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";

import { updateDataApi } from "../../../containers/admin/ConfigSection/utils";
import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import { useLocalStorageCache } from "../../../lib/i18n/hooks";
import { ApiStoreType, apiStoreAtom } from "../../admin/config/atoms";
import { resetApiStoreData } from "../../admin/config/utils";
import { useInitConfig } from "../../config/hooks/init";
import { frontEditHasBeenClosedXTimesState } from "../atoms/frontEdit";
import { frontEditCurrentContentEditsState } from "../atoms/frontEditCurrentContentEdits";
import { apiEncode } from "../utils/api-encode";
import useFrontEditContext from "./useFrontEditContext";

export type UseFrontEditActions = {
  cancelState: { disable: boolean; loading: boolean };
  publishState: { disable: boolean; loading: boolean };
  handleCancel: () => void;
  handlePublish: () => void;
  handleQuit: () => void;
};

export default function useFrontEditActions(): UseFrontEditActions {
  const { clear } = useLocalStorageCache();
  const { refreshConfig } = useInitConfig();
  const { setFrontEdit } = useFrontEditContext();
  const setHasBeenClosed = useSetRecoilState(frontEditHasBeenClosedXTimesState);
  const { t } = useTranslation("common");
  const [currentContentEdits, setCurrentContentEdits] = useRecoilState(
    frontEditCurrentContentEditsState,
  );
  const { currentUi, refresh } = useCurrentUi();
  const [apiStore, setApiStore] = useRecoilState<ApiStoreType>(apiStoreAtom);
  const publishState = {
    disable: currentContentEdits.length === 0 && (!apiStore || Object.keys(apiStore).length === 0),
    loading: false,
  };
  const cancelState = {
    disable: currentContentEdits.length === 0 && (!apiStore || Object.keys(apiStore).length === 0),
    loading: false,
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleCancel = () => {
    if (window.confirm(t("front-edit.cancel.confirm.message"))) {
      setCurrentContentEdits([]);
      resetApiStoreData(setApiStore, refresh);
    }
  };

  async function handlePublish() {
    let flushLabels = false;
    const unsavedConfigSection: string[] = [];
    for (const currentContentEdit of currentContentEdits) {
      if (!currentContentEdit.invalidForm) {
        const widgetId = currentContentEdit.key.widgetId;
        const labelKey = currentContentEdit.key.label?.namespaceLabelKey;
        const configSection = currentContentEdit.key.configSection;
        const jsonData = JSON.stringify(
          apiEncode(
            currentContentEdit.jsonSchema,
            currentContentEdit.jsonUiSchema,
            currentContentEdit.data,
          ),
        );

        let url = "/api/edit/";
        if (widgetId) {
          url += `widgets/${configSection}/${encodeURIComponent(widgetId)}`;
        } else if (labelKey) {
          url += `labels/${labelKey}`;
          flushLabels = true;
        } else {
          url += `${configSection}`;
        }
        await getAxiosClientInstance().put(url, jsonData, {
          baseURL: location.origin,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        });
        if (flushLabels) {
          await clear();
        }
        updateDataApi(apiStore, currentUi);
      } else {
        unsavedConfigSection.push(currentContentEdit.jsonSchema["i18n-title"]);
      }
    }

    if (unsavedConfigSection.length !== 0) {
      enqueueSnackbar(
        `${t("front-edit.unsaved-configuration.message")} ${unsavedConfigSection.map((u) => {
          return `- ${t(`admin:${u}`)} `;
        })}`,
        {
          variant: "warning",
        },
      );
    }
    // Quit front edit
    handleQuit();
  }

  const handleQuit = useCallback(() => {
    // Leave edit mode
    setFrontEdit(false);
    // Remove history
    setCurrentContentEdits([]);
    // Keep count of how much the front edit has been closed
    // to have up to date data in selectors
    setHasBeenClosed((v) => v + 1);
    // get Updated config
    refreshConfig();
    resetApiStoreData(setApiStore, refresh);
  }, [refresh, refreshConfig, setApiStore, setCurrentContentEdits, setFrontEdit, setHasBeenClosed]);

  return {
    cancelState,
    publishState,
    handleCancel,
    handlePublish,
    handleQuit,
  };
}
