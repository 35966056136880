import { GridSize } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { getConvertFooterWidget } from "../../../lib/widget-utils";
import { FooterWidget } from "../../../models/configuration/components/footerWidget";
import { Footer } from "../../../models/configuration/definitions/footer";
import { Type } from "../../../models/configuration/definitions/widget";

import { KFooterWidgetProps } from ".";

// TODO : remove when legacy filter will be deleted :
export const LEGACY_FOOTER_ID = "LEGACY_FOOTER_ID";
export function getFooterWidgetConfiguration(
  customerSectionConfiguration: JsonConfig[],
  widgetId: string,
): KFooterWidgetProps | undefined {
  let footerWidget: FooterWidget | Footer | undefined;
  // TODO : remove when legacy filter will be deleted :
  if (widgetId === LEGACY_FOOTER_ID) {
    const oldFooter = getAdminConfigSectionData<Footer>(
      customerSectionConfiguration,
      CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER,
    );
    footerWidget = getConvertFooterWidget(oldFooter);
  } else {
    const footerWidgetList = getAdminConfigSectionData<FooterWidget[]>(
      customerSectionConfiguration,
      CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET,
    );
    if (footerWidgetList?.length === undefined || footerWidgetList.length === 0) {
      logger.debug(`getFooterWidgetConfiguration - footerWidgetList is empty or undefined`);
      return;
    }
    footerWidget = footerWidgetList?.find((m) => m.id === widgetId);
    if (!footerWidget) {
      logger.debug(`getFooterWidgetConfiguration - can't find footerWidget widget id ${widgetId}`);
      return;
    }
  }
  if (footerWidget) {
    return {
      widgetId: (footerWidget as FooterWidget)?.id ?? LEGACY_FOOTER_ID,
      footerConfiguration: footerWidget as FooterWidget, // Add type assertion here
      type: Type.Footer,
    };
  }
  return undefined;
}
export const componentSizeFooter: Record<Breakpoint, boolean | GridSize> = {
  xs: 12,
  sm: 4,
  md: 2,
  lg: 2,
  xl: 2,
};
