import React, { useState } from "react";

import { KpkApiSheetFieldType } from "@keepeek/api-client";
import { getTranslatedAndSortedMediaFields, useSheetFields } from "@keepeek/commons";
import { Autocomplete, FormControl, LinearProgress, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Widget, WidgetProps } from "@rjsf/utils";
import { getI18n, useTranslation } from "react-i18next";

import { Type } from "../../../../models/configuration/components/businessFilter";
import { KAdminFields } from "../../../../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../../../../models/configuration/types/kAdminMediaFields";
import { convertToFieldList } from "../../../field-utils";
import { getDisplayTypeOptions } from "./utils";

const CustomFieldsSelectWidget: Widget = function ({
  label,
  options,
  onChange,
  required,
  value,
  schema,
  hideNonValidFieldsForPlainTextSearch = false,
}: WidgetProps & { hideNonValidFieldsForPlainTextSearch?: boolean }) {
  const { loading, sheetFields } = useSheetFields();
  const { t } = useTranslation();

  const translatedAndSortedMediaFields: KAdminMediaFields[] = getTranslatedAndSortedMediaFields();

  const fieldOrSection = schema["fieldOrSection"];

  const [unknownField, setUnknownField] = useState<boolean>(false);

  if (loading) {
    return <LinearProgress />;
  }
  const filteredSheetFields = hideNonValidFieldsForPlainTextSearch
    ? sheetFields?.filter((sheetField) => sheetField.type !== KpkApiSheetFieldType.CheckBox)
    : sheetFields;

  let completeFieldList: KAdminFields[] = [];
  if (options?.fieldListType === "functional") {
    completeFieldList = convertToFieldList(filteredSheetFields, []);
  } else if (options?.fieldListType === "technical") {
    completeFieldList = convertToFieldList([], translatedAndSortedMediaFields);
  } else {
    completeFieldList = convertToFieldList(filteredSheetFields, translatedAndSortedMediaFields);
  }

  // filter field relating to display type we want :
  if (options?.fieldListDisplayType) {
    let displayType: Type | undefined;
    switch (options.fieldListDisplayType) {
      case "facet":
        displayType = Type.Facet;
        break;
      case "facet-multi":
        displayType = Type.FacetMulti;
        break;
      case "advanced":
        displayType = Type.Advanced;
        break;
      default:
        console.error("type not implemented");
    }
    if (displayType) {
      completeFieldList = [
        ...completeFieldList.filter((field) =>
          getDisplayTypeOptions(field, sheetFields).some(
            (displayTypeOption) => displayTypeOption === displayType,
          ),
        ),
      ];
    }
  }

  const labelRender = required && label ? `${label}*` : label;

  const formControlStyle: SxProps<Theme> = {
    marginTop: 1,
    marginBottom: 1,
  };

  if (!fieldOrSection || fieldOrSection === "field") {
    const valueComputed = () => {
      if (value) {
        value = value.trim();
        const field = completeFieldList.find((f) => f.id === value);
        if (field) {
          return `${field.name} [${field.id}] (${field.type})`;
        }
        return value.includes(getI18n().t("unknownField"))
          ? value
          : `${value} (${getI18n().t("unknownField")})`;
      }
      return "";
    };
    return (
      <FormControl key={label} sx={formControlStyle}>
        <Autocomplete
          options={completeFieldList.map((field) => ({
            id: field.id,
            label: `${field.name} [${field.id}] (${field.type})`,
          }))}
          renderInput={(params) => (
            <TextField
              onKeyUp={(_e) => {
                setUnknownField(true);
              }}
              onBlur={(e) => {
                if (unknownField) {
                  setUnknownField(false);
                  // do not send a change if there is none compared to the original value.
                  // This allows on the one hand to avoid unnecessary rendering and
                  // on the other hand to protect against this anomaly reported during a non-reg:
                  // - If we open the autocomplete without selecting anything, and
                  // we click on the filter type select, the select does not render
                  // properly and the select options are completely outside the select...
                  if (e.target.value !== valueComputed()) {
                    onChange(e.target.value);
                  }
                }
              }}
              {...params}
              label={schema.title ? labelRender : null}
            />
          )}
          onChange={(_e, newValue) => {
            if (newValue?.id) {
              // do not send a change if there is none compared to the original value.
              // This allows on the one hand to avoid unnecessary rendering and
              // on the other hand to protect against this anomaly reported during a non-reg:
              // - If we open the autocomplete without selecting anything, and
              // we click on the filter type select, the select does not render
              // properly and the select options are completely outside the select...
              if (newValue.id !== value) {
                onChange(newValue.id);
              }
              setUnknownField(false);
            }
          }}
          value={valueComputed()}
          openOnFocus
          isOptionEqualToValue={(option, value) => option?.label === value}
          freeSolo
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl key={label} sx={formControlStyle}>
        <TextField
          value={value}
          label={labelRender}
          helperText={t("admin:fieldsSectionsItem.section.value.description")}
          onChange={({ target }) => onChange(target.value)}
        />
      </FormControl>
    );
  }
};

export default CustomFieldsSelectWidget;
